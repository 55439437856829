<template>
<div>
  <v-text-field
    v-if="!valuesPass"
    outlined
    rounded
    :dense="dense"
    :dark="dark"
    :value="value"
    @input="Fn($event)"
    clearable
    :label="label"
    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
    :type="show ? 'text' : 'password'"
    counter
    @click:append="show = !show"
  >
    <template v-if="randomPass" v-slot:append-outer>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" @click="generateRandomPass(randomValues)" > mdi-lock-reset </v-icon>
        </template>
        <span>Contraseña Aleatoria</span>
      </v-tooltip>
    </template>
  </v-text-field>
<v-text-field
    v-else
    outlined
    rounded
    :dense="dense"
    :dark="dark"
    :value="value"
    :rules="rules"
    @input="Fn({value:$event,...valuesPass})"
    clearable
    :label="label"
    maxlength="12"
    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
    :type="show ? 'text' : 'password'"
    counter
    @click:append="show = !show"
  >
    <template v-if="randomPass" v-slot:append-outer>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" @click="generateRandomPass(randomValues)" > mdi-lock-reset </v-icon>
        </template>
        <span>Contraseña Aleatoria</span>
      </v-tooltip>
    </template>
  </v-text-field>
</div>
</template>

<script>
export default {
  props: {
    label: String,
    Fn: Function,
    value: String,
    dark: Boolean,
    dense: Boolean,
    valuesPass: Object,
    rules: Array,
    // appen-outer props 
    generateRandomPass: Function,
    randomPass: Boolean,
    randomValues: Object
  },
  data() {
    return {
      show: false,
    };
  },
};
</script>

<style>
</style>