<template>
  <div id="login" style="height: 100%; overflow: hidden">
    <v-img
      src="@/assets/Logo-Radio-Trunk_negro.png"
      style="margin: 0px auto"
      width="15%"
    >
    </v-img>
    <v-container style="border-radius: 20px !important">
      <v-row align="center" justify="center" background-color="transparent">
        <v-col cols="8">
          <PasswordField
          class="mt-12"
            :Fn="updateRecoveryPassword"
            label="Contraseña"
            :value="password"
            :dark="false"
          />
        </v-col>
        <v-col cols="8"
          >
          <PasswordField
            :Fn="updateRecoveryPasswordVal"
            label="Confirmar Contraseña"
            :value="passwordVal"
            :dark="false"
          />
        </v-col>
        <v-col cols="6" align="center" justify="center">
          <v-btn x-large rounded outlined @click="RecoveryPassword();changeView()"
            >Cambiar contraseña</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import PasswordField from "../components/passwordField.vue";
export default {
  components: {
    PasswordField,
  },
  mounted() {
    this.setToken(this.$route.params.token);
  },
  computed: {
    ...mapState("Recovery", ["password", "passwordVal"]),
  },
  methods: {
    ...mapMutations("Recovery", [
      "updateRecoveryPassword",
      "updateRecoveryPasswordVal",
      "setToken",
    ]),
    ...mapActions("Recovery", ["RecoveryPassword"]),
    changeView(){
      this.$router.push('/')
    }
  },
};
</script>

<style scoped>
</style>